<template>
    <div ref="panel" class="with-sale flex full flex-column flex-item">
        <template v-if="loaded">
            <drug-sale-info-year class="with-sale-item" :txt="$route.params.txt" :class="{ active: type === 1 }"
                :isAccurate="isAccurate" :base-query="baseQuery" :realHeight="realHeight"
                :visit-id="visitId"></drug-sale-info-year>
            <drug-sale-info-quarter class="with-sale-item" :txt="$route.params.txt" :class="{ active: type === 2 }"
                :isAccurate="isAccurate" :base-query="baseQuery" :realHeight="realHeight"
                :visit-id="visitId"></drug-sale-info-quarter>
        </template>
    </div>
</template>

<script>
import DrugSaleInfoYear from './DrugSaleInfoYear'
import DrugSaleInfoQuarter from './DrugSaleInfoQuarter'

export default {
    components: { DrugSaleInfoYear, DrugSaleInfoQuarter },
    props: {
        baseQuery: {
            default: () => ({})
        },
        isAccurate: {
            default: false,
            type: Boolean,
        },
        visitId: {},
    },
    data() {
        return {
            // 1-年，2-季度
            type: 1,

            realHeight: 100,
            loaded: false,
        }
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 50
        this.loaded = true
    },
}
</script>